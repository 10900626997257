import { Link } from "react-router-dom"
import React from "react"

const Breadcrumb = ({ breadcrumb }) => (
  <div className="breadcrumb-inner">
    <ul className="page-list">
      {breadcrumb.map(({ text, to }, i) => (
        <li className={`rn-breadcrumb-item ${i + 1 === breadcrumb.length ? "active" : ""}`} key={text}>
          {to ? <Link to={to}>{text}</Link> : text}
        </li>
      ))}
    </ul>
  </div>
)

export default Breadcrumb
